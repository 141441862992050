<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <Loader v-if="!venue" />
      <div class="dashboard__container--header pb-3" v-if="venue">
        <div>
        <h1>{{venue.title}}</h1>
        </div>
        <span class="flex align-center justify-flex-end">
          <!-- <router-link :to="`/venues/` + venueInfo.id + `/qr`">
            <button class="btn btn__outlined btn__small mr-3">Get QR Code</button>
          </router-link> -->
          <!-- <button class="btn btn__outlined" @click="exportFollowers()">Followers: <span class="caption" v-if="venueFollowers">{{venueFollowers.length}}</span></button> -->
          <button class="btn btn__outlined btn__small" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
        </span>
      </div>
      <form ref="form" @submit.prevent>
      	<div class="dashboard__container--body" v-if="venue">
      		<div class="dashboard__container--body--col">

    				<div class="mb-3 whiteBack">
    					<label for="venueName">Jobsite Name:</label>
    					<input type="text" v-model.trim="venue.title" id="venueName" />
              <div class="caption mt-3" v-if="venue && venue.address">
                {{venue.address.street_number}} {{venue.address.street}}
              </div>
              <div class="caption" v-if="venue && venue.address">
                {{venue.address.city}}, {{venue.address.state}} {{venue.address.zip}}
              </div>
    				</div>
            <div class="mb-3 whiteBack">
              <label for="venueShortCode">ShortCode:</label>
              <input type="text" v-model.trim="venue.shortCode" id="venueShortCode" />
            </div>
            <div class="mb-3 whiteBack">
              <div class="caption mb-1 strong">Update Address:</div>
              <label>
                <gmap-autocomplete
                  @place_changed="setPlace" :options="{fields: ['geometry', 'address_components']}" placeholder="Address">
                </gmap-autocomplete>
              </label>
            </div>
            <div class="whiteBack">
              <div v-if="clients.length >= 1">
                <label for="client">Default Clients:</label>
                <v-select
                  class="mt-2 mb-2"
                  label="title" 
                  :options="clients"
                  multiple
                  v-model="venue.client"
                  >
                </v-select>
              
              </div>
            </div>
            <div class="whiteBack mb-3 mt-3">
              <h4>WC Submit Status</h4>
              <div class="mb-3 mt-2">
                <label for="wc">Added to WC Policy:</label>
                <input type="checkbox" v-model="venue.wcPolicy" id="wc" class="ml-3" @change="updateVenue()" />
              </div>
            </div>
      		</div>

          
          
      		<div class="dashboard__container--body--col" v-if="venue && venue.center">
            <div>
        			<gmap-map v-if="venue && venue.center" class="mb-5" :center="venue.center" :zoom="12"  style="width: 100%; height: 300px">
                <GmapMarker
                  :position="venue.center"
                  :clickable="false"
                >
                </GmapMarker>
              </gmap-map>
            </div>
      		</div>

          <div class="floating_buttons">
            <button class="btn btn__success btn__large" @click="updateVenue()">
            Update Jobsite
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>

      	</div>
      </form>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')
import 'instantsearch.css/themes/satellite-min.css'

export default {
  name: 'jobsite',
  data: () => ({
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    place: '',
    currentPlace: null,
    performingRequest: false,
  }),
   computed: {
    ...mapState(['currentUser', 'venueInfo', 'userProfile', 'clients']),
    // ...mapState(['venueInfo', 'venueEvents', 'clients', 'jobs', 'mgrs', 'venueFollowers']),
    venue() {
      return this.venueInfo
    },
  },
  created () {
    this.$store.dispatch("getVenueFromId", this.$route.params.id);
    if (!this.clients || this.clients.length < 1) {
      this.$store.dispatch("getClients")
    }
    
  },
  components: {
    Loader,
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.venue.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.venue.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality') {
              this.venue.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.venue.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.venue.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.venue.address.zip = component['short_name'];
          }
        }
      }
    },
    addVenue() {
    	let place = this.place
    	let center = this.center
    	let address = this.address
    	let newVenue = {
    		address: address,
    		title: place.name,
    		center: center,
    		visible: place.visible
    	}
    	console.log(newVenue)
      this.$store.dispatch('addVenue', newVenue)
    },
    updateVenue() {
      this.performingRequest = true
      let venue = this.venueInfo
      let store = this.$store
        console.log(venue)
        this.$store.dispatch('updateVenue', venue)
        setTimeout(() => {
          this.performingRequest = false
        }, 2000)
    },
    exportFollowers() {
      let logFields = {
          user: this.currentUser.email,
          export: 'Followers (Venues) Export',
          venue: this.venue.id
      }
      this.$store.dispatch('sendExportLog', logFields)
      
      const exportHeaders = [
        "First Name",
        "Email",
        "Phone",
        "Venue",
      ]
      const exportItems = [];
      for (var key in this.venueFollowers) {
        exportItems.push([
          this.venueFollowers[key].name,
          this.venueFollowers[key].email,
          this.venueFollowers[key].phone,
          this.venueFollowers[key].venueName,
        ])
      }
      console.log(exportItems)
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },
    deleteVenue() {
      let venue = this.venueInfo
      this.$store.dispatch('deleteVenue', venue.id)
      let url = `/venues`
      router.push(url)
          let logFields = {
          staffMember: this.currentUser.email,
          export: 'Delete Venue',
          venue: this.venue,
      }
      this.$store.dispatch('sendVenueLog', logFields)
    },
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearVenueState')
    this.$store.dispatch('clearClientsState')
  	this.$store.dispatch('clearErrors')
    console.log(this)
  }
}
</script>